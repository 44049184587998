import { lazy } from "react";
import { Navigate } from "react-router";
const InvoiceDetails = lazy(() => import("../pages/Invoices/InvoiceDetails"));
const InvoicesOfUser = lazy(() => import("../pages/Invoices/InvoicesOfUser"));
const OrderCharges = lazy(() => import("../pages/Invoices/OrderCharges"));
const InvoicingUsers = lazy(() => import("../pages/Invoices/InvoicingUsers"));
const InvoicesOfBatch = lazy(() => import("../pages/Invoices/InvoicesOfBatch"));
const BatchesWithInvoices = lazy(() =>
  import("../pages/Invoices/BatchesWithInvoices")
);

const DraftOrders = lazy(() => import("../pages/Orders/DraftOrders"));
const UnsyncedOrders = lazy(() => import("../pages/Orders/UnsyncedOrders"));
// // import { isDevDomain } from "../utils";

const Login = lazy(() => import("../pages/authentication/Login"));
const Onboarding = lazy(() => import("../pages/authentication/Onboarding"));
const ForgetPassword = lazy(() =>
  import("../pages/authentication/Login/ForgetPassword")
);
const EmailVerification = lazy(() => import("../pages/EmailVerification"));
const Register = lazy(() => import("../pages/authentication/Register"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

const AnalyticsScreen = lazy(() =>
  import("../pages/Dashboard/AnalyticsScreen")
);
const Profile = lazy(() => import("../pages/Profile"));
// // const Wallet = lazy(() => import("../pages/Wallet"));
const Payments = lazy(() => import("../pages/Payments"));
const Settings = lazy(() => import("../pages/Settings"));
const Integrations = lazy(() => import("../pages/Integrations"));
const Shops = lazy(() => import("../pages/Shops"));
const AddShop = lazy(() => import("../pages/Shops/AddNewShop/index.js"));
const ShopSettings = lazy(() => import("../pages/Shops/ShopSettings/index.js"));

const EcommerceChannels = lazy(() =>
  import("../pages/Shops/EcommerceChannels")
);
const ShopRedirect = lazy(() => import("../pages/Shops/Redirects"));

const AllAddedBlanks = lazy(() =>
  import("../pages/LocalInventory/AllAddedBlanks")
);
const Listings = lazy(() => import("../pages/listings/Listings"));

const ListingDetails = lazy(() => import("../pages/listings/ListingDetails"));
const ItemsIndex = lazy(() => import("../pages/items/ItemsIndex"));
const Batches = lazy(() => import("../pages/batches/Batches"));
const BatchDetais = lazy(() => import("../pages/batches/BatchDetails"));
const BatcheItemDetails = lazy(() =>
  import("../pages/batches/BatchItemDetails")
);
const PurchaseOrders = lazy(() =>
  import("../pages/purchaseOrders/PurchaseOrders")
);
// const Orders = lazy(() => import("../pages/Orders"));
const MyOrders = lazy(() => import("../pages/Orders/AllOrders"));

const NewPurchaseOrder = lazy(() =>
  import("../pages/purchaseOrders/NewPurchaseOrder")
);
const NewPurchaseOrderDetail = lazy(() =>
  import("../pages/purchaseOrders/PurchaseOrders/PurchaseOrderDetails")
);
const OrderDetails = lazy(() => import("../pages/Orders/OrderDetails"));

const OrderItemDetails = lazy(() => import("../pages/Orders/OrderItemDetails"));
const DraftOrderDetails = lazy(() =>
  import("../pages/Orders/DraftOrders/DraftOrderDetails")
);
const AdminDraftOrders = lazy(() => import("../pages/Orders/AdminDraftOrders"));
const AdminDraftOrderDetails = lazy(() =>
  import("../pages/Orders/AdminDraftOrders/AdminDraftOrderDetails")
);
const Products = lazy(() => import("../pages/products/Products"));

const NewProduct = lazy(() => import("../pages/products/NewProduct"));

const NewProductIndex = lazy(() =>
  import("../pages/products/SelectedNewProducts/NewProductIndex")
);

const BlankProductDetails = lazy(() =>
  import("../pages/products/SelectedNewProducts/BlankProductDetails")
);
const NewProductDesign = lazy(() =>
  import("../pages/products/SelectedNewProducts/NewProductDesign")
);
const NewNecklaceDesign = lazy(() =>
  import("../pages/products/SelectedNewProducts/NewNecklaceDesign")
);
// // const NewStickerDesign = lazy(() =>
// //   import("../pages/products/SelectedNewProducts/NewStickerDesign")
// // );
const NewKissCutStickerDesign = lazy(() =>
  import("../pages/products/SelectedNewProducts/NewKissCutStickerDesign")
);
// // const NewProductDesignOld = lazy(() =>
// //   import("../pages/products/SelectedNewProducts/NewProductDesignOld")
// // );
const CsvUpload = lazy(() => import("../pages/products/CsvUpload"));
const LocalInventoryProducts = lazy(() =>
  import("../pages/LocalInventory/Products")
);
// // const AddProductsForm = lazy(() =>
// //   import("../pages/LocalInventory/Products/AddProductsForm")
// // );
const NewManualProduct = lazy(() =>
  import("../pages/LocalInventory/NewManualProduct")
);
const LocalInventoryVariants = lazy(() =>
  import("../pages/LocalInventory/Variants")
);
// // const InventoryProductDetail = lazy(() =>
// //   import("../pages/Inventory/InventoryProductDetails")
// // );
// // const InventoryCustomers = lazy(() =>
// //   import("../pages/Inventory/InventoryCustomers")
// // );
// // const InventoryCart = lazy(() => import("../pages/Inventory/InventoryCart"));
// // const InventoryCheckout = lazy(() =>
// //   import("../pages/Inventory/InventoryCheckout")
// // );
// // const InventoryShops = lazy(() => import("../pages/Inventory/InventoryShops"));
const InventoryVariantEdit = lazy(() =>
  import("../pages/Inventory/VariantEdit")
);
const InventoryAddVariant = lazy(() =>
  import("../pages/Inventory/InventoryAddVariant")
);
const InventoryAddPrintedProduct = lazy(() =>
  import("../pages/Inventory/InventoryAddPrintedProduct")
);
const InventoryAddProduct = lazy(() =>
  import("../pages/Inventory/InventoryAddProduct")
);

const ManualOrders = lazy(() => import("../pages/ManualOrders"));
const ManualOrdersNew = lazy(() => import("../pages/ManualOrders-new"));

const Upgrade = lazy(() => import("../pages/Upgrade"));
const PricingContainer = lazy(() => import("../pages/PricingContainer"));
// // const ProductEditor = lazy(() => import("../pages/products/ProductEditor"));

// //BINS
const Bins = lazy(() => import("../pages/Bins"));
const BinDetails = lazy(() => import("../pages/Bins/BinDetails"));

const Shipments = lazy(() => import("../pages/Shipments"));
const Addresses = lazy(() => import("../pages/Addresses"));

const ShopifyAppEditor = lazy(() => import("../pages/ShopifyAppEditor"));

// //Tiers page
const Tiers = lazy(() => import("../pages/tiers/index"));

const BatchByItems = lazy(() => import("../pages/items/BatchByItems"));
const BatchByOrders = lazy(() => import("../pages/items/BatchByOrders"));
const BatchSchedulers = lazy(() => import("../pages/batches/BatchSchedulers"));
const ShippingSpeed = lazy(() => import("../pages/ShippingSpeed/index.js"));
const PopupStoresShippingSpeed = lazy(() =>
  import("../pages/PopupStoresShippingOptions")
);
const ShippingPrice = lazy(() => import("../pages/ShippingPrice"));
const Category = lazy(() => import("../pages/Category"));
const Locations = lazy(() => import("../pages/Locations"));
const Members = lazy(() => import("../pages/Members"));
const EnterpriseBranding = lazy(() => import("../pages/EnterpriseBranding"));
const Enterprises = lazy(() => import("../pages/Enterprises"));

// // const Asana = lazy(() => import("../pages/HelpDesk/Asana"));
const AQCItems = lazy(() => import("../pages/batches/AQCItems"));
const QualityControlFailed = lazy(() =>
  import("../pages/batches/QualtiControlFailed")
);
const ShareAccess = lazy(() => import("../pages/ShareAccess"));
const LateOrders = lazy(() => import("../pages/Orders/LateOrders"));
const ImageBins = lazy(() => import("../pages/ImageBins"));
const Catalog = lazy(() => import("../pages/products/Catalog"));
const Nester = lazy(() => import("../pages/Nester"));
const ShippingOptions = lazy(() => import("../pages/ShippingOptions"));
const EmbroideryOptions = lazy(() => import("../pages/EmbroideryOptions"));
const EnterpriseDomains = lazy(() => import("../pages/EnterpriseDomains"));
const BespokeAdminPanelEnterprises = lazy(() =>
  import("../pages/BespokeAdminPanel/Enterprises")
);
const BespokeAdminPanelUsers = lazy(() =>
  import("../pages/BespokeAdminPanel/Users")
);
const BlankProducts = lazy(() =>
  import("../pages/blankProducts/TypeOfProduct")
);
const SupplierBlankProduct = lazy(() =>
  import("../pages/blankProducts/SupplierBlankProduct")
);
const ManualBlankProduct = lazy(() =>
  import("../pages/blankProducts/ManualBlankProduct")
);
const RiverrBlankProduct = lazy(() =>
  import("../pages/blankProducts/RiverrBlankProduct")
);

const authProtectedRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/analytics-screen", exact: true, component: AnalyticsScreen },
  { path: "/profile", component: Profile },
  { path: "/wallet", component: Payments },
  // // { path: "/payments", component: Payments },
  { path: "/integrations", component: Integrations, exact: true },
  { path: "/ecommerce-channels", component: EcommerceChannels, exact: true },
  { path: "/shops", component: Shops, exact: true },
  { path: "/add-shop", component: AddShop, exact: true },
  { path: "/shop/settings", component: ShopSettings, exact: true },
  { path: "/shop/settings/:shopId", component: ShopSettings, exact: true },

  {
    path: "/shops/redirects/:platformName",
    component: ShopRedirect,
    exact: true,
  },
  { path: "/settings", component: Settings, exact: true },
  { path: "/all-added-blanks", component: AllAddedBlanks, exact: true },
  { path: "/listings", component: Listings, exact: true },

  { path: "/listings/:id", component: ListingDetails, exact: true },
  { path: "/items", component: ItemsIndex, exact: true },
  { path: "/batches", component: Batches, exact: true },
  { path: "/batches/:id", component: BatchDetais, exact: true },
  { path: "/batch-item/:id", component: BatcheItemDetails, exact: true },
  { path: "/purchase-orders", component: PurchaseOrders, exact: true },
  { path: "/orders", component: MyOrders, exact: true },
  // // { path: "/orders-new", component: MyOrders, exact: true },

  { path: "/manual-orders", component: ManualOrders, exact: true },
  { path: "/manual-orders-new", component: ManualOrdersNew, exact: true },

  { path: "/pricing", component: Upgrade, exact: true },
  { path: "/pricing-table", component: PricingContainer, exact: true },
  { path: "/orders/:id", component: OrderDetails, exact: true },

  {
    path: "/orders/:orderId/items/:id",
    component: OrderItemDetails,
    exact: true,
  },
  { path: "/new-purchase-order", component: NewPurchaseOrder },
  { path: "/purchase-order/:id", component: NewPurchaseOrderDetail },

  { path: "/draft-orders", component: DraftOrders, exact: true },
  { path: "/draft-orders/:id", component: DraftOrderDetails, exact: true },
  { path: "/admin-draft-orders", component: AdminDraftOrders, exact: true },
  {
    path: "/admin-draft-orders/:id",
    component: AdminDraftOrderDetails,
    exact: true,
  },
  { path: "/unsynced-orders", component: UnsyncedOrders, exact: true },
  { path: "/late-orders", component: LateOrders, exact: true },
  { path: "/products", component: Products, exact: true },

  { path: "/products/new", component: NewProduct, exact: true },
  { path: "/products/new-product", component: NewProductIndex, exact: true },

  {
    path: "/products/new-product/:id",
    component: NewProductDesign,
    exact: true,
  },
  {
    path: "/products/new-product/:id/details",
    component: BlankProductDetails,
    exact: true,
  },
  {
    path: "/products/new-necklace/:id/details",
    component: BlankProductDetails,
    exact: true,
  },
  {
    path: "/products/sticker/:id/details",
    component: BlankProductDetails,
    exact: true,
  },
  {
    path: "/products/new-sticker/:id/details",
    component: BlankProductDetails,
    exact: true,
  },

  {
    path: "/products/new-necklace/:id",
    component: NewNecklaceDesign,
    exact: true,
  },
  {
    path: "/products/sticker/:id",
    component: NewKissCutStickerDesign,
    exact: true,
  },
  // // {
  // //   path: "/products/new-sticker/:id",
  // //   component: NewStickerDesign,
  // //   exact: true,
  // // },
  {
    path: "/products/nester/:id",
    component: Nester,
    exact: true,
  },
  // // { path: "/products/new/editor", component: ProductEditor, exact: true },
  { path: "/products/csv-upload", component: CsvUpload, exact: true },

  // { path: "/unsynced-orders", component: UnsyncedOrders, exact: true },
  // { path: "/unsynced-orders", component: UnsyncedOrders, exact: true },
  {
    path: "/inventory-products",
    component: LocalInventoryProducts,
    exact: true,
  },
  // // { path: "/inventory-products/new", component: AddProductsForm, exact: true },
  {
    path: "/inventory-products/new/manual-product",
    component: NewManualProduct,
    exact: true,
  },
  {
    path: "/inventory-products/:id",
    component: LocalInventoryVariants,
    exact: true,
  },
  {
    path: "/inventory-variants",
    component: LocalInventoryVariants,
    exact: true,
  },
  // // { path: "/inventory-product-detail", component: InventoryProductDetail },
  // { path: "/inventory-customers", component: InventoryCustomers },
  // { path: "/inventory-cart", component: InventoryCart },
  // { path: "/inventory-checkout", component: InventoryCheckout },
  // { path: "/inventory-shops", component: InventoryShops },
  { path: "/inventory-add-product", component: InventoryAddProduct },
  { path: "/inventory-variant-edit/:id", component: InventoryVariantEdit },
  { path: "/inventory-add-variant", component: InventoryAddVariant },
  { path: "/inventory-add-printed", component: InventoryAddPrintedProduct },
  // // { path: "/shopify-user", component: ShopifyUser, exact: true },

  // //bins
  { path: "/bins", component: Bins, exact: true },
  { path: "/bins/:id", component: BinDetails, exact: true },

  // // INVOICES

  {
    path: "/users",
    component: InvoicingUsers,
    exact: true,
  },
  {
    path: "/users/:id/invoices",
    component: InvoicesOfUser,
    exact: true,
  },
  {
    path: "/invoices",
    component: InvoicesOfBatch,
    exact: true,
  },
  {
    path: "/batch-invoices",
    component: BatchesWithInvoices,
    exact: true,
  },
  {
    path: "/order-charges",
    component: OrderCharges,
    exact: true,
  },
  {
    path: "/users/:id/invoices/:invoice_id",
    component: InvoiceDetails,
    exact: true,
  },

  { path: "/shipments", component: Shipments },

  { path: "/addresses", component: Addresses },
  { path: "/tiers", component: Tiers, expect: true },
  { path: "/batch-by-items", component: BatchByItems, expect: true },
  // // { path: "/help-desk", component: Asana, expect: true },
  { path: "/batch-by-orders", component: BatchByOrders, expect: true },
  { path: "/batch-schedulers", component: BatchSchedulers, expect: true },
  { path: "/shipping-speed", component: ShippingSpeed, exact: true },
  {
    path: "/popup-stores-shipping-options",
    component: PopupStoresShippingSpeed,
    exact: true,
  },
  { path: "/shipping-price", component: ShippingPrice, exact: true },
  { path: "/product-categories", component: Category, exact: true },
  { path: "/locations", component: Locations, exact: true },
  { path: "/members", component: Members, exact: true },
  { path: "/business-branding", component: EnterpriseBranding, exact: true },
  { path: "/enterprises", component: Enterprises, exact: true },
  { path: "/aqc-items", component: AQCItems, exact: true },
  {
    path: "/quality-control-failed-items",
    component: QualityControlFailed,
    exact: true,
  },
  {
    path: "/share-access",
    component: ShareAccess,
    exact: true,
  },
  {
    path: "/image-bins",
    component: ImageBins,
    exact: true,
  },
  {
    path: "/products/third-party-catalog",
    component: Catalog,
    exact: true,
  },
  {
    path: "/nester",
    component: Nester,
    exact: true,
  },
  {
    path: "/shipping-options",
    component: ShippingOptions,
    exact: true,
  },
  {
    path: "/embroidery-options",
    component: EmbroideryOptions,
    exact: true,
  },
  {
    path: "/enterprise-domains",
    component: EnterpriseDomains,
    exact: true,
  },
  {
    path: "/bespoke-admin-panel",
    component: BespokeAdminPanelEnterprises,
    exact: true,
  },
  {
    path: "/bespoke-admin-panel/:id/users",
    component: BespokeAdminPanelUsers,
    exact: true,
  },
  {
    path: "/blank-products/new",
    component: BlankProducts,
    exact: true,
  },
  {
    path: "/blank-products/new/supplier-product",
    component: SupplierBlankProduct,
    exact: true,
  },
  {
    path: "/blank-products/new/manual-product",
    component: ManualBlankProduct,
    exact: true,
  },
  {
    path: "/blank-products/new/riverr-product",
    component: RiverrBlankProduct,
    exact: true,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/onboarding", component: Onboarding, exact: true },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/editor", component: ShopifyAppEditor },
  { path: "/email-verification", component: EmailVerification },
];

export { authProtectedRoutes, publicRoutes };
